import Audio from "./Audio";
import Button from "./Button";
import styles from "./Callout.module.css";
import Shape from "./Shape";

export default function Callout({
  heading,
  subheading,
  cta,
  callback,
  showAudio,
}) {
  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <Shape
          width="40svh"
          height="40svh"
          innerColor="var(--yellow)"
          outerColor="var(--yellow)"
          color="var(--brown)"
        >
          <div className={styles.content}>
            {showAudio ? (
              <Audio />
            ) : (
              <>
                <h1 className={styles.heading}>{heading}</h1>
                <p className={styles.subheading}>{subheading}</p>
                {cta && (
                  <Button svh onClick={callback}>
                    <span dangerouslySetInnerHTML={{ __html: cta }} />
                  </Button>
                )}
              </>
            )}
          </div>
        </Shape>
      </div>
    </div>
  );
}
