import { createContext, useEffect, useState } from "react";
import {
  Outlet,
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
} from "react-router-dom";
import ErrorPage from "./ErrorPage";
import ForceError from "./ForceError";
import LegalsPage from "./LegalsPage";
import { privacyPolicyLoader, termsAndConditionsLoader } from "./loaders";
import MainPage from "./MainPage";
import { dateToString, getLocale } from "./utils";

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage body="An error has occured" />,
    Component: Root,
    children: [
      {
        index: true,
        Component: MainPage,
      },
      {
        path: "day",
        Component: MainPage,
      },
      {
        path: "terms-and-conditions",
        Component: LegalsPage,
        loader: termsAndConditionsLoader,
      },
      {
        path: "privacy-policy",
        Component: LegalsPage,
        loader: privacyPolicyLoader,
      },
      {
        path: "error",
        Component: ForceError,
      },
    ],
  },
  {
    path: "*",
    Component: ErrorPage,
  },
]);

export const AppContext = createContext();

export default function App() {
  return <RouterProvider router={router} />;
}

function Root() {
  const lang = getLocale();
  const [preloaderError, setPreloaderError] = useState(false);

  useEffect(() => {
    document.body.classList.add(`lang-${lang}`);
  }, [lang]);

  if (window.location.search.includes("reset")) {
    localStorage.removeItem(dateToString());
  }

  return (
    <AppContext.Provider value={{ lang, preloaderError, setPreloaderError }}>
      <ScrollRestoration />
      <Outlet />
    </AppContext.Provider>
  );
}
