import { useContext, useEffect, useState } from "react";
import { AppContext } from "./App";
import styles from "./Loading.module.css";
import Screen from "./Screen";
import Shape from "./Shape";
import { getAssetsHostname, useTranslations } from "./utils";

function loadVideoUsingFetch(url, retries = 3) {
  return new Promise((resolve, reject) => {
    async function attemptLoad(attempt) {
      try {
        const response = await fetch(url);
        if (response.ok) {
          const blob = await response.blob();
          resolve({ url, blob });
        } else if (attempt < retries) {
          await delay(1000 * attempt);
          return attemptLoad(attempt + 1);
        } else {
          reject(response.status);
        }
      } catch (error) {
        if (attempt < retries) {
          await delay(1000 * attempt);
          return attemptLoad(attempt + 1);
        } else {
          reject(error);
        }
      }
    }
    attemptLoad(1);
  });
}

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default function Loading({ callback }) {
  const [screenState, setScreenState] = useState("show");
  const { text } = useTranslations("loading");
  const { lang, setPreloaderError } = useContext(AppContext);

  const assetsHostname = getAssetsHostname(lang);

  useEffect(() => {
    const urls = [
      `${assetsHostname}/videos/loop_${lang}.mp4`,
      `${assetsHostname}/videos/lose_${lang}.mp4`,
      `${assetsHostname}/videos/music_${lang}.mp4`,
      `${assetsHostname}/videos/money_${lang}.mp4`,
      `${assetsHostname}/videos/bundle_${lang}.mp4`,
    ];
    const promises = urls.map((url) => loadVideoUsingFetch(url));
    promises.push(delay(500));

    if (window.plausible) {
      window.plausible("loader", {
        props: { loader: "preloadStarted" },
      });
    }

    Promise.all(promises)
      .then(() => {
        if (window.plausible) {
          window.plausible("loader", {
            props: { loader: "preloadSuccess" },
          });
        }
      })
      .catch((error) => {
        setPreloaderError(true);
        if (window.plausible) {
          window.plausible("loader", {
            props: { loader: "preloadFailed" },
          });
        }
      })
      .finally(() => {
        setScreenState("hide");
        setTimeout(() => {
          callback();
        }, 500);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Screen state={screenState}>
      <Shape spin></Shape>
      <span className={styles.text}>{text}</span>
    </Screen>
  );
}
