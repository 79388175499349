import * as Sentry from "@sentry/browser";
import { useEffect } from "react";
import { useRouteError } from "react-router-dom";
import styles from "./ErrorPage.module.css";
import Panel from "./Panel";

export default function ErrorPage({ body = "This page cannot be found." }) {
  let error = useRouteError();

  useEffect(() => {
    if (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  }, [error]);

  return (
    <div className={styles.root}>
      <Panel>
        <h2>Sorry!</h2>
        <p className={styles.content}>{body}</p>
        <p className={styles.content}>
          Please click <a href="/">here</a> to go to the homepage
        </p>
      </Panel>
    </div>
  );
}
