import { useContext, useEffect, useState } from "react";
import AlreadyPlayedPage from "./AlreadyPlayedPage";
import { AppContext } from "./App";
import ConfirmAge from "./ConfirmAge";
import { API_URL, BUNDLE, MONEY } from "./constants";
import Form from "./Form";
import Game from "./Game";
import Intro from "./Intro";
import InvalidCodePage from "./InvalidCodePage";
import Loading from "./Loading";
import Snowflakes from "./Snowflakes";
import Thanks from "./Thanks";
import { dateToString } from "./utils";

const CHECK_CODE = "check-code";
const INVALID_CODE = "invalid-code";
const ALREADY_PLAYED = "already-played";
const LOADING = "loading";
const CONFIRM_AGE = "confirm-age";
const INTRO = "intro";
const GAME = "game";
const FORM = "form";
const THANKS = "thanks";

export default function MainPage() {
  const [showSnowflakes, setShowSnowflakes] = useState(true);
  const [renderSnowflakes, setRenderSnowflakes] = useState(true);

  useEffect(() => {
    if (!showSnowflakes) {
      setTimeout(() => {
        setRenderSnowflakes(false);
      }, 1000);
    }
  }, [showSnowflakes]);

  return (
    <>
      {renderSnowflakes && <Snowflakes opacity={showSnowflakes ? 1 : 0} />}
      <Content setShowSnowflakes={setShowSnowflakes} />
    </>
  );
}

function Content({ setShowSnowflakes }) {
  const [state, setState] = useState(CHECK_CODE);
  const [uid, setUid] = useState(null);
  const [outcome, setOutcome] = useState(null);
  const { lang } = useContext(AppContext);

  useEffect(() => {
    async function validateCode() {
      try {
        const code = new URLSearchParams(window.location.search).get("code");
        const response = await fetch(
          `${API_URL}/validate-code/?code=${code}&country=${lang}`
        );
        const data = await response.json();
        if (response.ok) {
          setState(LOADING);
        } else {
          throw new Error(data.error);
        }
      } catch (e) {
        setState(INVALID_CODE);
      }
    }
    if (state === CHECK_CODE) {
      let alreadyPlayed = false;
      try {
        alreadyPlayed = localStorage.getItem(dateToString());
      } catch (e) {
        // Ignore
      }
      if (alreadyPlayed) {
        setState(ALREADY_PLAYED);
      } else {
        validateCode();
      }
    }
    if (window.plausible) {
      window.plausible("state", { props: { state } });
    }
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  if (state === ALREADY_PLAYED) {
    return <AlreadyPlayedPage />;
  }

  if (state === INVALID_CODE) {
    return <InvalidCodePage />;
  }

  if (state === LOADING) {
    return <Loading callback={() => setState(CONFIRM_AGE)} />;
  }

  if (state === CONFIRM_AGE) {
    return <ConfirmAge callback={() => setState(INTRO)} />;
  }

  if (state === INTRO) {
    return (
      <Intro
        callback={() => {
          setState(GAME);
          setShowSnowflakes(false);
        }}
      />
    );
  }

  if (state === GAME) {
    return (
      <Game
        callback={(outcome) => {
          setOutcome(outcome);
          if (outcome === MONEY || outcome === BUNDLE) {
            setState(FORM);
          }
        }}
        setUid={setUid}
      />
    );
  }

  if (state === FORM) {
    return (
      <Form outcome={outcome} callback={() => setState(THANKS)} uid={uid} />
    );
  }

  if (state === THANKS) {
    return <Thanks />;
  }
}
