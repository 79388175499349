import {
  EventType,
  useRive,
  useStateMachineInput,
} from "@rive-app/react-canvas";
import classNames from "classnames";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "./App";
import Callout from "./Callout";
import { API_URL, BUNDLE, LOSE, MONEY, MUSIC } from "./constants";
import styles from "./Game.module.css";
import Screen from "./Screen";
import ShapeButton from "./ShapeButton";
import { dateToString, getAssetsHostname, getTranslations } from "./utils";

function getCalloutTranslations(lang, outcome) {
  const callout = getTranslations(lang, "callout");
  return callout[outcome];
}

const videoProps = {
  className: styles.video,
  muted: true,
  playsInline: true,
  preload: "auto",
};

function loopOutcome(event) {
  event.target.currentTime = 100 / 30;
  const playPromise = event.target.play();
  if (playPromise !== undefined) {
    playPromise
      .then(function () {
        // Looping succeeded
      })
      .catch(function (error) {
        // Looping failed, not much we can do here
      });
  }
}

export default function Game({ callback, setUid }) {
  const [outcome, setOutcome] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [loopDone, setLoopDone] = useState(false);
  const [active, setActive] = useState(false);
  const [riveLoaded, setRiveLoaded] = useState(false);
  const [outcomePlaying, setOutcomePlaying] = useState(false);
  const [grabberDone, setGrabberDone] = useState(false);
  const [callout, setCallout] = useState(null);
  const { lang, preloaderError } = useContext(AppContext);
  const [showAudio, setShowAudio] = useState(false);

  const loopRef = useRef(null);
  const loseRef = useRef(null);
  const moneyRef = useRef(null);
  const bundleRef = useRef(null);
  const musicRef = useRef(null);

  const assetsHostname = getAssetsHostname(lang);

  const { rive, RiveComponent } = useRive({
    src: "/grabber.riv",
    stateMachines: "machine",
    autoplay: true,
    onLoad: () => {
      if (window.plausible) {
        window.plausible("game", { props: { gameState: "riveLoaded" } });
      }
      setRiveLoaded(true);
    },
  });

  const onRiveEventReceived = (riveEvent) => {
    if (riveEvent?.data?.name === "Complete") {
      setGrabberDone(true);
    }
  };

  useEffect(() => {
    if (rive) {
      rive.on(EventType.RiveEvent, onRiveEventReceived);
    }
  }, [rive]);

  const winTrigger = useStateMachineInput(rive, "machine", "win");
  const loseTrigger = useStateMachineInput(rive, "machine", "lose");

  useEffect(() => {
    if (active && window.plausible) {
      window.plausible("game", { props: { gameState: "activated" } });
      if (preloaderError) {
        window.plausible("loader", {
          props: { loader: "preloadRecovered" },
        });
      }
    }
  }, [active]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (window.plausible) {
      window.plausible("game", { props: { gameState: "started" } });
    }
  }, []);

  useEffect(() => {
    if (loopDone && grabberDone) {
      if (outcome === MONEY) {
        moneyRef.current.play();
      } else if (outcome === BUNDLE) {
        bundleRef.current.play();
      } else if (outcome === MUSIC) {
        musicRef.current.play();
      } else if (outcome === LOSE) {
        loseRef.current.play();
      }
    }
  }, [loopDone, grabberDone, outcome]);

  async function handlePlayButtonClicked() {
    try {
      localStorage.setItem(dateToString(), "played");
    } catch (e) {
      // Ignore
    }
    setButtonClicked(true);

    if (window.plausible) {
      window.plausible("game", { props: { gameState: "buttonClicked" } });
    }

    let response;
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      response = await fetch(`${API_URL}/create-entry/`, {
        method: "POST",
        timeout: 10000,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: code,
          country: lang,
        }),
      });
    } catch (error) {
      response = null;
    }

    let data;
    if (!response?.ok) {
      try {
        const errorText = await response.text();
        console.error("Error creating entry:", errorText);
      } catch (error) {
        console.error("Error creating entry");
      }
    } else {
      if (window.plausible) {
        window.plausible("game", { props: { gameState: "outcomeLoaded" } });
      }
      try {
        data = await response.json();
      } catch (error) {
        try {
          const errorText = await response.text();
          console.error("Failed to parse JSON response:", errorText);
        } catch (error) {
          console.error("Failed to parse JSON response");
        }
      }
    }

    let newOutcome, uid;
    if (!data?.uid || !data?.outcome) {
      newOutcome = LOSE;
    } else {
      newOutcome = data.outcome;
      uid = data.uid;
      if (newOutcome === MONEY) {
        try {
          localStorage.setItem("moneyUid", uid);
        } catch (error) {
          console.error("Error setting moneyUid:", error);
        }
      }
    }

    if (newOutcome === LOSE) {
      setTimeout(() => {
        setCallout(getCalloutTranslations(lang, LOSE));
      }, 3000);
      loseTrigger?.fire();
    } else {
      winTrigger?.fire();
      if (newOutcome === MUSIC) {
        setTimeout(() => {
          setCallout(getCalloutTranslations(lang, MUSIC));
        }, 3200);
      } else if (newOutcome === BUNDLE) {
        setTimeout(() => {
          setCallout(getCalloutTranslations(lang, BUNDLE));
        }, 3200);
      } else if (newOutcome === MONEY) {
        setTimeout(() => {
          setCallout(getCalloutTranslations(lang, MONEY));
        }, 3200);
      }
    }
    setUid(uid);
    setOutcome(newOutcome);
  }

  return (
    <Screen>
      <div
        className={classNames(styles.inner, {
          [styles.innerShow]: active && riveLoaded,
        })}
      >
        <video
          ref={loseRef}
          src={`${assetsHostname}/videos/lose_${lang}.mp4`}
          {...videoProps}
          style={{
            zIndex: outcomePlaying && outcome === LOSE ? 1 : null,
          }}
          onPlay={() => {
            setOutcomePlaying(true);
          }}
          onEnded={(event) => {
            event.target.currentTime = 56 / 30;
            event.target.play();
          }}
        />
        <video
          ref={moneyRef}
          src={`${assetsHostname}/videos/money_${lang}.mp4`}
          {...videoProps}
          style={{
            zIndex: outcomePlaying && outcome === MONEY ? 1 : null,
          }}
          onPlay={() => {
            setOutcomePlaying(true);
          }}
          onEnded={loopOutcome}
        />
        <video
          ref={bundleRef}
          src={`${assetsHostname}/videos/bundle_${lang}.mp4`}
          {...videoProps}
          style={{
            zIndex: outcomePlaying && outcome === BUNDLE ? 1 : null,
          }}
          onPlay={() => {
            setOutcomePlaying(true);
          }}
          onEnded={loopOutcome}
        />
        <video
          ref={musicRef}
          src={`${assetsHostname}/videos/music_${lang}.mp4`}
          {...videoProps}
          style={{
            zIndex: outcomePlaying && outcome === MUSIC ? 1 : null,
          }}
          onPlay={() => {
            setOutcomePlaying(true);
          }}
          onEnded={loopOutcome}
        />
        <video
          ref={loopRef}
          src={`${assetsHostname}/videos/loop_${lang}.mp4`}
          autoPlay
          {...videoProps}
          onPlay={() => {
            setActive(true);
          }}
          onEnded={(e) => {
            if (outcome === null) {
              loopRef.current.play();
            } else {
              setLoopDone(true);
            }
          }}
        />
        <RiveComponent className={styles.grabber} />
        <div className={styles.cta}>
          <ShapeButton
            color="var(--brown)"
            backgroundColor="var(--yellow)"
            className={classNames(styles.button, {
              [styles.shrink]: outcomePlaying,
            })}
            disabled={!active || buttonClicked}
            showSpinner={buttonClicked}
            onClick={handlePlayButtonClicked}
          >
            {getTranslations(lang, "game").cta}
          </ShapeButton>
        </div>
        {callout && (
          <Callout
            showAudio={showAudio}
            {...callout}
            callback={() => {
              if (outcome === MUSIC) {
                setShowAudio(true);
              } else {
                setActive(false);
                setTimeout(() => {
                  callback(outcome);
                }, 500);
              }
            }}
          />
        )}
      </div>
    </Screen>
  );
}
