import classNames from "classnames";
import { useState } from "react";
import Button from "./Button";
import styles from "./Intro.module.css";
import Panel from "./Panel";
import Screen from "./Screen";
import typeStyles from "./typography.module.css";
import { useTranslations } from "./utils";
export default function Intro({ callback }) {
  const [screenState, setScreenState] = useState("show");
  const { heading, subheading, body, cta } = useTranslations("intro");

  return (
    <Screen state={screenState}>
      <Panel>
        <h2 className={classNames(typeStyles.heading, styles.heading)}>
          {heading}
        </h2>
        <div className={classNames(typeStyles.subheading, styles.subheading)}>
          {subheading}
        </div>
        <div
          className={typeStyles.body}
          dangerouslySetInnerHTML={{ __html: body }}
        />
        <Button
          onClick={() => {
            setScreenState("hide");
            setTimeout(() => {
              callback();
            }, 500);
          }}
        >
          {cta}
        </Button>
      </Panel>
    </Screen>
  );
}
