import camelcaseKeys from "camelcase-keys";
import classNames from "classnames";
import { useState } from "react";
import Button from "./Button";
import { API_URL, MONEY } from "./constants";
import styles from "./Form.module.css";
import Screen from "./Screen";
import { useTranslations } from "./utils";

function decamelizeKeys(obj) {
  // Using a hand-rolled version of decamelize-keys because the library breaks
  // on Safari 16.2 and below.
  const result = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const snakeKey = key.replace(
        /[A-Z]/g,
        (letter) => `_${letter.toLowerCase()}`
      );
      result[snakeKey] = obj[key];
    }
  }
  return result;
}

function joinErrorsIfArray(errors) {
  return Array.isArray(errors) ? errors.join(", ") : errors;
}

function Input({ label, type, name, errors, required = true }) {
  let fieldErrors = errors?.[name];
  fieldErrors = joinErrorsIfArray(fieldErrors);

  if (fieldErrors !== undefined && typeof fieldErrors !== "string") {
    console.error(fieldErrors);
  }
  return (
    <label
      className={classNames({
        [styles.label]: true,
        [styles[type]]: true,
      })}
    >
      <span dangerouslySetInnerHTML={{ __html: label }}></span>
      <input
        className={classNames(styles.input, fieldErrors && styles.error)}
        type={type}
        name={name}
        required={required}
      ></input>
    </label>
  );
}

function FormFields({ callback, fieldErrors, nonFieldErrors, clearErrors }) {
  const {
    address1,
    address2,
    city,
    confirmEmail,
    cta,
    dateOfBirth,
    email,
    firstName,
    lastName,
    phone,
    postcode,
    residentialAddress,
    shippingAddress,
    mode,
    acceptTerms,
  } = useTranslations("form");

  let addressLabel;
  if (mode === "bundle") {
    addressLabel = shippingAddress;
  } else {
    addressLabel = residentialAddress;
  }

  return (
    <form
      className={styles.form}
      onSubmit={(event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        callback(formData);
      }}
    >
      <div className={styles.formSection}>
        <Input
          label={firstName}
          type="text"
          name="firstName"
          errors={fieldErrors}
        />
        <Input
          label={lastName}
          type="text"
          name="lastName"
          errors={fieldErrors}
        />
        <Input label={email} type="email" name="email" errors={fieldErrors} />
        <Input
          label={confirmEmail}
          type="email"
          name="confirmEmail"
          errors={fieldErrors}
        />
        <Input
          label={dateOfBirth}
          type="text"
          name="dateOfBirth"
          errors={fieldErrors}
        />
        <Input label={phone} type="tel" name="phone" errors={fieldErrors} />
      </div>
      <div className={styles.formSection}>
        <h3 className={styles.formHeading}>{addressLabel}</h3>
        <Input
          label={address1}
          type="text"
          name="address1"
          errors={fieldErrors}
        />
        <Input
          label={address2}
          type="text"
          name="address2"
          required={false}
          errors={fieldErrors}
        />
        <Input label={city} type="text" name="city" errors={fieldErrors} />
        <Input
          label={postcode}
          type="text"
          name="postcode"
          errors={fieldErrors}
        />
        <Input
          label={acceptTerms}
          type="checkbox"
          name="acceptTerms"
          errors={fieldErrors}
        />
      </div>
      {nonFieldErrors && <p className={styles.error}>{nonFieldErrors}</p>}
      <Button onClick={clearErrors}>{cta}</Button>
    </form>
  );
}

export default function Form({ outcome, callback: gameCallback, uid }) {
  const [screenState, setScreenState] = useState("show");
  const { bundle, money } = useTranslations("form");
  const { heading, subheading } = outcome === MONEY ? money : bundle;
  const [fieldErrors, setFieldErrors] = useState(null);
  const [nonFieldErrors, setNonFieldErrors] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  async function submitCallback(formData) {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    const data = decamelizeKeys(Object.fromEntries(formData));
    let response;
    try {
      response = await fetch(`${API_URL}/update-entry/${uid}/`, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    } catch (error) {
      console.error(error);
      setNonFieldErrors("Sorry, something went wrong. Please try again later.");
      setSubmitting(false);
    }
    if (response?.ok) {
      setScreenState("hide");
      setTimeout(() => {
        gameCallback();
      }, 1000);
    } else {
      try {
        let errorData = await response.json();
        errorData = camelcaseKeys(errorData, { deep: true });
        setFieldErrors(errorData);
        if (errorData.nonFieldErrors) {
          setNonFieldErrors(joinErrorsIfArray(errorData.nonFieldErrors));
        } else {
          setNonFieldErrors("Please check the form for errors.");
        }
      } catch (error) {
        console.error(error);
        setNonFieldErrors(
          "Sorry, something went wrong. Please try again later."
        );
      }
      setSubmitting(false);
    }
  }

  function clearErrors() {
    setFieldErrors(null);
    setNonFieldErrors(null);
  }

  let mode;
  if (outcome === MONEY) {
    mode = "money";
  } else {
    mode = "bundle";
  }

  return (
    <Screen state={screenState} fitHeight={false}>
      <div className={styles.root}>
        <h1 className={styles.heading}>{heading}</h1>
        <p className={styles.subheading}>{subheading}</p>
        <FormFields
          callback={submitCallback}
          fieldErrors={fieldErrors}
          nonFieldErrors={nonFieldErrors}
          clearErrors={clearErrors}
          mode={mode}
        />
      </div>
    </Screen>
  );
}
