import classNames from "classnames";
import styles from "./Button.module.css";

export default function Button({ children, onClick, svh }) {
  return (
    <button
      className={classNames(styles.root, { [styles.svh]: svh })}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
