import camelcaseKeys from "camelcase-keys";
import { API_URL } from "./constants";
import { getLocale } from "./utils";
const cache = {};

export async function privacyPolicyLoader({ request }) {
  const data = await getDataCached(
    request.signal,
    "privacy-policy",
    getLocale()
  );
  return data;
}

export async function termsAndConditionsLoader({ request }) {
  const data = await getDataCached(
    request.signal,
    "terms-and-conditions",
    getLocale()
  );
  return data;
}

async function getDataCached(signal, type, locale = null) {
  let url;
  if (locale) {
    url = `${API_URL}/${type}/?locale=${locale}`;
  } else {
    url = `${API_URL}/${type}/`;
  }
  if (cache[url]) {
    return cache[url];
  }
  const data = await getData(signal, url);
  if (cache[url] === undefined) {
    cache[url] = data;
  }
  return data;
}

async function getData(signal, url) {
  let response;
  try {
    response = await fetch(url, {
      signal,
    });
  } catch (error) {
    console.log("Could not load", error);
    throw new Error("Could not load", url);
  }
  if (response.status !== 200) {
    throw new Error("Not found", url);
  }
  try {
    const data = await response.json();
    return camelcaseKeys(data);
  } catch (error) {
    console.log("Could not parse", error);
    throw new Error("Could not parse", url);
  }
}
