import classNames from "classnames";
import styles from "./Screen.module.css";

export default function Screen({ children, color, state, fitHeight = true }) {
  return (
    <div
      className={classNames(styles.root, {
        [styles.show]: state === "show",
        [styles.hide]: state === "hide",
        [styles.fitHeight]: fitHeight,
      })}
      style={{ backgroundColor: color }}
    >
      {children}
    </div>
  );
}
