import { useLoaderData } from "react-router-dom";
import styles from "./LegalsPage.module.css";

export default function LegalsPage() {
  const { content, heading } = useLoaderData();

  return (
    <div className={styles.root}>
      <h1>{heading}</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: content || "",
        }}
      ></div>
    </div>
  );
}
