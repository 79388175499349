import { useEffect, useRef, useState } from "react";
import styles from "./Audio.module.css";
import calloutStyles from "./Callout.module.css";

const audioFiles = [
  { name: "Peanut Butter Kings", url: "/audio/peanut-butter-kings.mp3" },
  { name: "March of the Peanut", url: "/audio/march-of-the-peanut.mp3" },
  { name: "Nut Rest Ye Choccy Cup", url: "/audio/nut-rest-ye-choccy-cup.mp3" },
];

export default function Audio() {
  const STORAGE_KEY = "audio-index";
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const getCurrentIndex = () => {
    try {
      const stored = localStorage.getItem(STORAGE_KEY);
      return stored ? parseInt(stored) : 0;
    } catch (e) {
      return 0;
    }
  };

  const currentIndex = getCurrentIndex();
  const audioFile = audioFiles[currentIndex];

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const nextIndex = (currentIndex + 1) % audioFiles.length;
    try {
      localStorage.setItem(STORAGE_KEY, nextIndex);
    } catch (e) {
      // Ignore
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <p className={calloutStyles.subheading} style={{ marginTop: "-5svh" }}>
        "{audioFile.name}"
      </p>
      <button
        className={styles.button}
        onClick={togglePlayPause}
        aria-label={isPlaying ? "Pause" : "Play"}
      >
        {isPlaying ? (
          <div className={styles.pauseIcon} />
        ) : (
          <div className={styles.triangleIcon} />
        )}
      </button>
      <audio ref={audioRef}>
        <source src={audioFile.url} type="audio/mpeg" />
      </audio>
    </>
  );
}
