import Screen from "./Screen";
import styles from "./Thanks.module.css";
import typeStyles from "./typography.module.css";
import { useTranslations } from "./utils";

export default function Thanks() {
  const { heading, subheading } = useTranslations("thanks");

  return (
    <Screen state="show">
      <div className={styles.root}>
        <h1 className={typeStyles.heading}>{heading}</h1>
        <p className={typeStyles.subheading}>{subheading}</p>
      </div>
    </Screen>
  );
}
