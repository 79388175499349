import classNames from "classnames";
import styles from "./Shape.module.css";
import { ReactComponent as ShapeSVG } from "./shape.svg";

export default function Shape({
  outerColor = "var(--sand)",
  innerColor = "var(--brown)",
  width = "12svh",
  height = "12svh",
  spin,
  children,
}) {
  return (
    <div
      style={{ width, height }}
      className={classNames(styles.root, spin && styles.spin)}
    >
      <ShapeSVG
        className={styles.outer}
        style={{ color: outerColor }}
      ></ShapeSVG>
      <ShapeSVG className={styles.inner} style={{ color: innerColor }} />
      <div className={styles.content}>{children}</div>
    </div>
  );
}
