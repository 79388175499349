export const API_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000";

export const API_URL = `${API_BASE}/api`;

export const MONEY = "money";
export const BUNDLE = "bundle";
export const MUSIC = "music";
export const LOSE = "lose";

export const ASSETS_HOSTNAME = "https://assets.djsanta-reeses.co.uk";
