import { useContext } from "react";
import { AppContext } from "./App";
import translations from "./translations.json";

export function getTranslations(lang, key) {
  return translations[lang][key];
}

export function useTranslations(key) {
  const { lang } = useContext(AppContext);
  return getTranslations(lang, key);
}

export function dateToString() {
  const today = new Date();
  const year = today.getFullYear();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  return `${year}-${month}-${day}`;
}

export function getLocale() {
  let lang = "gb";
  if (
    window.location.hostname.includes("djsanta-reeses.de") ||
    window.location.search.includes("lang=de")
  ) {
    lang = "de";
  }
  return lang;
}

export function getAssetsHostname(lang) {
  return lang === "de"
    ? "https://assets.djsanta-reeses.de"
    : "https://assets.djsanta-reeses.co.uk";
}
