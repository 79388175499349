import classNames from "classnames";
import Shape from "./Shape";
import styles from "./ShapeButton.module.css";

export default function ShapeButton({
  onClick,
  children,
  className,
  color,
  backgroundColor,
  disabled,
  showSpinner,
}) {
  return (
    <button
      className={classNames(styles.root, className)}
      onClick={onClick}
      style={{ color }}
      disabled={disabled}
    >
      <Shape
        outerColor={backgroundColor}
        innerColor={backgroundColor}
        width="9svh"
        height="9svh"
        spin
      />
      <div className={styles.content}>
        {showSpinner ? <div className={styles.spinner} /> : children}
      </div>
    </button>
  );
}
